/**
 * Generates a unique, incremental ID with a namespaced prefix.
 */
export function* namespaceIdGenerator(
  prefix?: string
): IterableIterator<string> {
  let count = 0;
  while (true) {
    count += 1;
    yield prefix ? `${prefix}_${count}` : `${count}`;
  }
}
