import {
  Channel,
  ChannelMessage,
  ChannelMessageTag,
  IChannel,
  IChannelMessage,
  IChannelSubscription,
} from '@v2/lib/channel';
import {
  ActionsMap,
  ConnectedItem,
  ConsumeMode,
  ConsumptionStrategy,
  ProvideMap,
} from '@v2/types/state_controller';
import {isString, isUndefined, nextTick} from '@v2/utils';
import {StateControllerStore} from './stores/store';
import {hasProperty} from '@v2/utils/objects';
import dayjs from 'dayjs';

interface SetConsumerResponse<
  TProvide extends ProvideMap,
  Key extends keyof TProvide,
> {
  getValue: () => [
    current: TProvide[Key] | undefined,
    previous: TProvide[Key] | undefined,
  ];
  runCallback: () => void;
  unsubscribe: () => void;
}

export class ChannelController<
  TActions extends ActionsMap,
  TProvide extends ProvideMap,
> {
  channel: IChannel;

  private _providerKeys = new Map<string, symbol>();

  private actionsRegistry = new StateControllerStore<
    (payload: TActions[keyof TActions]) => unknown,
    TActions
  >('actions');

  private providerRegistry = new StateControllerStore<
    TProvide[keyof TProvide],
    TProvide
  >('provide');

  private consumerRegistry = new StateControllerStore<
    ConsumptionStrategy<TProvide, keyof TProvide>,
    TProvide
  >('consume');

  private subscription: IChannelSubscription = {
    filter: () => true,
    handler: this.onChannelMessage.bind(this),
  };

  constructor(channel: string | IChannel) {
    this.channel = isString(channel) ? new Channel(channel) : channel;
    this.channel.subscribe([this.subscription]);
  }

  private logEventMessage(message: IChannelMessage) {
    const tags = Array.from(message.tags());
    const allTags = tags.map(tag => tag.key()).join();

    if (window.__DEBUG__) {
      const title = [
        `[Event from ${this.channel.id}: ${dayjs().format('HH:mm:ss.SSS')}]`,
        allTags,
      ].join(' ');

      console.groupCollapsed(title);
      tags.forEach(tag => {
        console.groupCollapsed(tag.key());
        console.log(JSON.stringify(tag.value(), null, 2));
        console.groupEnd();
      });
      console.groupEnd();
    }
  }

  private triggerProviderCallbacks<Key extends keyof TProvide>(
    provide: {key: Key; property: TProvide[Key]},
    newValue: TProvide[keyof TProvide],
    oldValue: TProvide[keyof TProvide]
  ) {
    const providedItems = this.providerRegistry.getStoreItemsByKey(provide.key);
    const providerKeys = providedItems.map(item => item.key);
    const listeners = providerKeys
      .map(key => {
        const consumers = this.consumerRegistry.getStoreItemsByKey(key);
        const notifyOnChange = consumers.filter(
          consumer => consumer.value.mode !== ConsumeMode.ON_DEMAND
        );
        const notifiables = notifyOnChange.filter(item => {
          const {selector} = item.value;
          return !selector || selector(newValue) !== selector(oldValue);
        });
        return notifiables;
      })
      .flat();
    listeners.forEach(listener => listener.value.callback(newValue, oldValue));
  }

  private onChannelMessage(message: IChannelMessage) {
    if (!this.actionsRegistry.hasStoreItems()) return;
    for (const tag of message.tags()) {
      const key = tag.key() as keyof TActions;
      const {value} = tag.value() as {value: TActions[typeof key]};
      const storeItems = this.actionsRegistry.getStoreItemsByKey(key);

      if (!storeItems || storeItems.length === 0) return;

      storeItems.forEach(storeItem => {
        const {item, value: fn} = storeItem;
        fn?.call(item, value);
      });
    }
  }

  unregister(item: ConnectedItem): void {
    this.actionsRegistry.unregister(item);
  }

  register(item: ConnectedItem): void {
    this.actionsRegistry.register(item);
  }

  createProviderKey(key: string): symbol {
    if (this._providerKeys.has(key)) {
      throw new Error(`The key ${key} is already being provided.`);
    }
    const provideSymbol = Symbol(`provide-${String(key)}`);
    this._providerKeys.set(key, provideSymbol);
    return provideSymbol;
  }

  getProviderKey(key: string): symbol | undefined {
    return this._providerKeys.get(key);
  }

  getProvidedValue(
    item: TProvide,
    symbol?: symbol
  ):
    | {current: TProvide[keyof TProvide]; previous?: TProvide[keyof TProvide]}
    | undefined {
    if (!symbol || !(symbol in item)) {
      return;
    }
    return item[symbol as keyof TProvide] as TProvide[keyof TProvide];
  }

  setProvider<
    T extends Record<string | symbol, unknown>,
    Key extends keyof TProvide & string,
  >(item: T, provide: {key: Key; property: TProvide[Key]}): T {
    const {key, property} = provide;

    if (!hasProperty(item, property)) {
      return item;
    }

    this.providerRegistry.saveStoreItem(item, key, property);

    const provideSymbol = this.createProviderKey(key);
    const currentValue = item[property];

    Object.defineProperties(item, {
      [provideSymbol]: {
        value: {
          current: currentValue,
          previous: undefined,
        },
        writable: true,
        enumerable: false,
      },
      [property]: {
        get: () => {
          const obj = item[provideSymbol] as {
            current: TProvide[keyof TProvide];
          };
          return obj?.current;
        },
        set: value => {
          const oldValue = (
            item[provideSymbol] as {
              current: TProvide[keyof TProvide];
            }
          ).current;
          // @ts-expect-error Property converted to getter/setter
          item[provideSymbol] = {
            current: value,
            previous: oldValue,
          };
          this.triggerProviderCallbacks(provide, value, oldValue);
        },
      },
    });

    return item;
  }

  setConsumer<Key extends keyof TProvide>(
    item: ConnectedItem,
    provideKey: Key,
    strategy: ConsumptionStrategy<TProvide, Key>
  ): SetConsumerResponse<TProvide, Key> {
    this.consumerRegistry.saveStoreItem(item, provideKey, strategy);

    const getValue = (): [
      current: TProvide[Key] | undefined,
      previous: TProvide[Key] | undefined,
    ] => {
      const provideCtx = this.providerRegistry.getStoreItemsByKey(provideKey);
      if (!provideCtx || provideCtx.length === 0) {
        return [undefined, undefined];
      }
      const {item, key: providerKey} = provideCtx[0];
      const symbol = this.getProviderKey(String(providerKey));
      const provider = item as TProvide;
      const value = this.getProvidedValue(provider, symbol);

      return [value?.current, value?.previous];
    };

    const runCallback = () => {
      const values = getValue();
      return strategy.callback(...values);
    };

    if (isUndefined(strategy.runOnInit) || strategy.runOnInit) {
      runCallback();
    }

    const unsubscribe = () => {
      this.removeConsumer(item, provideKey, strategy);
    };

    return {
      getValue,
      runCallback,
      unsubscribe,
    };
  }

  removeConsumer<Key extends keyof TProvide>(
    item: ConnectedItem,
    provideKey: Key,
    strategy: ConsumptionStrategy<TProvide, Key>
  ) {
    this.consumerRegistry.deleteStoreItem(item, provideKey, strategy);
  }

  onAction<Key extends keyof TActions>(
    item: ConnectedItem,
    action: Key,
    callback: (payload: TActions[Key]) => unknown
  ): VoidFunction {
    return this.actionsRegistry.saveStoreItem(
      item,
      action,
      callback as (payload: TActions[keyof TActions]) => unknown
    );
  }

  offAction<Key extends keyof TActions>(
    item: ConnectedItem,
    action: Key,
    callback: (payload: TActions[Key]) => unknown
  ): boolean {
    return this.actionsRegistry.deleteStoreItem(
      item,
      action,
      callback as (payload: TActions[keyof TActions]) => unknown
    );
  }

  createActionMessage(
    action: keyof TActions,
    payload?: TActions[keyof TActions]
  ): IChannelMessage | undefined {
    if (!isString(action)) return;
    const tag = ChannelMessageTag.create(action, {
      value: payload,
    });
    return ChannelMessage.fromTags(tag);
  }

  emitAction<Key extends keyof TActions>(
    action: Key,
    payload?: TActions[Key],
    sync?: boolean
  ): void {
    const message = this.createActionMessage(action, payload);
    if (!message) {
      console.error('Invalid message', action, payload);
      return;
    }

    if (sync) {
      this.logEventMessage(message);
      this.channel.publish(message);
      return;
    }

    nextTick(() => {
      this.logEventMessage(message);
      this.channel.publish(message);
    });
  }
}
