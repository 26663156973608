import {ReactiveController, ReactiveControllerHost} from 'lit';

export class ListenDisconnectController implements ReactiveController {
  constructor(
    private host: ReactiveControllerHost,
    private disconnectCallback: VoidFunction
  ) {
    this.host.addController(this);
  }

  hostDisconnected() {
    this.disconnectCallback();
  }
}
