import {LitElement} from 'lit';
import {getRule, getParams} from '../utils/routes';
import {FlywheelRouter} from './router';

// A Vortex component; extends LitElement and HTMLElement
export class FlywheelComponent extends LitElement {
  static tagRegistry = new Set<string>();
  static paths: string[] = [];
  static router: FlywheelRouter;

  protected path: string;
  private disconnectController = new AbortController();

  lifecycleComplete = false;
  queries: URLSearchParams;

  constructor() {
    super();

    this.path = window.location.pathname || '';
    this.queries = new URLSearchParams(window.location.search);

    if (this.tagName) {
      FlywheelComponent.tagRegistry.add(this.tagName);
    }
  }

  private finishLifecylce() {
    if (!this.lifecycleComplete) {
      this.rendered();
      this.lifecycleComplete = true;
      this.dispatchEvent(new CustomEvent('rendered'));
    }
  }

  /**
   * Abort controller signal
   * Useful for automatically remove listeners when the component is removed from the DOM
   */
  get disconnectSignal() {
    return this.disconnectController.signal;
  }

  beforeLoad(): void {}
  load(): void {}
  rendered(): void {}

  // Returns valid route params if there's a
  // matching path
  get params(): {[k: string]: string | number} {
    const pathname = this.path;
    const rule = getRule(pathname, FlywheelComponent.paths);
    return getParams(pathname, rule) || {};
  }

  get router(): FlywheelRouter {
    return FlywheelComponent.router;
  }

  connectedCallback() {
    super.connectedCallback();
    this.disconnectController = new AbortController();

    // Mutation observer for after render/load hook
    const observer = new MutationObserver(() => this.finishLifecylce());
    const context = (this.shadowRoot?.getRootNode() ||
      this.getRootNode()) as Node;

    if (context) {
      observer.observe(context, {
        attributes: false,
        childList: true,
        subtree: true,
      });

      setTimeout(() => observer.disconnect(), 0);
    }

    // Before load hook
    this.beforeLoad = this.beforeLoad.bind(this);
    this.beforeLoad();

    this.render();

    // On load hook
    this.load = this.load.bind(this);
    this.load();
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.disconnectController.abort();
    this.dispatchEvent(new CustomEvent('disconnect'));
  }

  firstUpdated() {
    this.finishLifecylce();
  }
}
