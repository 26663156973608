import {App} from './lib/app';
import {routes} from './routes';
import {ignite} from '@gl/kiln';
import {FlywheelComponent} from './lib/component';
import {NewRelic} from './lib/new_relic';

import '@radix-ui/themes/styles.css';
import './common/styles/app.scss';
import '@gl/ui/global.scss';

const app = new App({
  name: 'VortexFrontend',
  routes,
});

app.init();

addEventListener('DOMContentLoaded', () => {
  // Initiates Kiln.
  ignite(FlywheelComponent.tagRegistry);
  // Instantiates NewRelic's browser agent.
  NewRelic.init();
});
