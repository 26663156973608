import {Nullable} from '@v2/types/general';
import {isNil} from './index';
import {hasProperty} from './objects';
import {hashString} from './strings';

/**
 * Generates a range given a head and tail
 */
export function* range(head: number, tail: number) {
  for (let i = head; i <= tail; i++) {
    yield i;
  }
}

/**
 * Get value by string hash
 */
export function getValueByHash<T>(arr: T[], str: string): T {
  if (arr.length === 0) {
    throw new Error(
      'Cannot get value from empty array. Please provide a non-empty array.'
    );
  }
  const hash = hashString(str);
  const value = arr[hash % arr.length];
  return value!;
}

/**
 * Paginates an array (i.e, paginate[arr, 2])
 */
export function paginate<T>(array: T[], page: number, limit = 10): T[] {
  return array.slice((page - 1) * limit, page * limit);
}

/**
 * Determines whether or not an item in array is the last item by index.
 */
export function isLastIndex(index: number, arr: unknown[]): boolean {
  return !!(index === arr.length - 1);
}

/**
 * Get last item.
 */
export function last<T>(arr: T[] | [] | undefined): T | undefined {
  if (!arr || arr.length === 0) return;
  return arr[arr.length - 1];
}

/**
 * Get first item.
 */
export function first<T>(arr: T[] | [] | undefined): T | undefined {
  if (!arr || arr.length === 0) return;
  return arr[0];
}

/*
 * Array Equality
 */
export function checkEquality<T = unknown>(
  a: T[],
  b: T[],
  compareFunction = (a: T, b: T) => Object.is(a, b)
) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => compareFunction(val, b[index]))
  );
}

const DEFAULT_GET_VALUE = <T, K>(item: T): K | undefined => {
  return hasProperty<T, 'id', K>(item, 'id') ? item.id : undefined;
};

const DEFAULT_COMPARE = <K>(a: K | undefined, b: K | undefined): boolean => {
  return a === b;
};

/*
 * Find an item in an array
 * Defaults for objects with id and JS equality
 * but both the value extraction and comparison logic may be overwritten.
 */
export function findItem<T, K>(
  items: T[],
  value: K,
  getValue: (item: T) => K | undefined = DEFAULT_GET_VALUE,
  compareFn: (a: K | undefined, b: K | undefined) => boolean = DEFAULT_COMPARE
) {
  return items.find(item => compareFn(getValue(item), value));
}

/**
 * Ensures an array value is returned from an array or non-array parameter
 */
export function ensureArrayValue<T>(value?: T | T[] | Nullable<T>): T[] {
  if (Array.isArray(value)) {
    return value;
  }
  return isNil(value) ? [] : [value];
}

/**
 * Predicate that checks whether or not there's only one value.
 */
export function onlyOne<T>(value: T[]): boolean {
  return value.length === 1;
}

/**
 * HOF for whether or not the length of a given item is greater than
 * a given number.
 */
export function gt<T>(array: T[]) {
  return function (floor: number): boolean {
    return array.length > floor;
  };
}
