import {v4} from 'uuid';
import {isString} from '.';

/* Create a hacked named type to avoid crossing strings with ids */
export type UUID = ReturnType<typeof v4> & {__kind__: 'UUID-v4'};

// Regular expression to check if string is a valid UUID
const VALID_UUID_REGEX = /^[a-f\d]{8}-(?:[a-f\d]{4}-){3}[a-f\d]{12}$/i;

function isValidUUID(string: string): boolean {
  return VALID_UUID_REGEX.test(string);
}

/*
 * Returns a type that extends string in a way
 * to block other types to be accepted
 * It has no implication on runtime,
 * and id is a plain string then
 */
export function createId(): UUID {
  return v4() as UUID;
}

export function isId(id: unknown): id is UUID {
  return isString(id) && isValidUUID(id);
}

export function parseId(id: unknown): UUID {
  if (isId(id)) return id;
  throw new Error(`${id} is not a valid id`);
}
