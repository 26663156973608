/**
 * Determines the equality of two arrays
 */
function arrayMatch(arr1: unknown[], arr2: unknown[]) {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
}

/**
 * Creates an object of parameters based on current pathname and rule.
 * i.e.: parseUrl("/audiences/3", "audiences/:id") => { id: 3 }
 */
export function getParams(
  pathname: string,
  rule: string
): {
  [k: string]: string | number;
} | void {
  if (!pathname || !rule) return;
  const pathSegments = pathname.split('/');
  const ruleSegments = rule.split('/');
  const vectors = ruleSegments
    .map((segment, i) => [segment, pathSegments[i]])
    .filter(([segment]) => segment.includes(':'))
    .map(([segment, slug]) => {
      return {[segment.replace(':', '')]: slug};
    });
  return Object.assign({}, ...vectors);
}

/**
 * Returns the appropriate rule from a list of rules for a given pathname.
 * Made to be used in conjunction with parseUrl.
 * i.e.: getRule("/audiences/3", ["/audiences/:id", "audiences"]) => "audiences/:id"
 */
export function getRule(pathname: string, rules: string[]): string {
  if (!pathname || !rules || !Array.isArray(rules) || !(rules.length > 0)) {
    return '';
  }

  // const patternMatch = /(?<!\()\/(?![\w\s]*[\)])/ this one was causing bugs in safari with look behind. Removed it, seems to work great still and now safari works
  const patternMatch = /\/(?![\w\s]*[\)])/;
  const pathSegments = pathname.split(patternMatch);
  const exclude = (path: string[], indices: number[]) =>
    path.filter((_, i) => {
      return !indices.includes(i);
    });

  const rule = rules.filter(rule => {
    const ruleSegments = rule.split(patternMatch);
    const params = ruleSegments.filter(s => s.includes(':'));
    const indices = params.map(p => ruleSegments.indexOf(p));

    for (const param of params) {
      if (param.match(/\((.*?)\)/g)) {
        const idx = ruleSegments.indexOf(param);
        const value = pathSegments[idx];
        const pathPattern = param.match(/\(([^)]+)\)/)?.[1];
        if (!pathPattern) {
          continue;
        }

        const regex = new RegExp(pathPattern);
        if (!regex || !pathPattern || !value) continue;

        if (!value.match(regex)) {
          return false;
        }
      }
    }

    return (
      arrayMatch(
        exclude(pathSegments, indices),
        exclude(ruleSegments, indices)
      ) && pathSegments.length === ruleSegments.length
    );
  })[0];

  return rule && rule.length ? rule : '';
}

export function checkCurrentUrlForSegment(segment: string): boolean {
  const currentUrl = window.location.href;
  return currentUrl.includes(segment);
}
