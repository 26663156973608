import {ChannelController} from '@v2/controllers/state_controller';
import {AnyObject, Nullable} from '@v2/types/general';
import {BrowserHistory} from './browser_history';
import {
  HistoryControllerActionMap,
  HistoryControllerActions,
} from './history_state_controller';

type QueryParameterListenerHandler<T extends AnyObject> = (
  currentValue: T,
  previousValue: T
) => unknown;

type LocationListenerHandler = (
  currentValue: URL,
  previousValue: URL
) => unknown;

export const historyStateController = new ChannelController<
  HistoryControllerActionMap,
  {}
>('history-channel');

export const browserHistory = new BrowserHistory(window.history);
browserHistory.subscribe(location =>
  historyStateController.emitAction(
    HistoryControllerActions.LOCATION_CHANGED,
    location
  )
);

function hasQueryParametersChanged(
  location: {previous: URL; current: URL},
  queryParameters?: Nullable<string[]>
) {
  const {previous, current} = location;
  if (!queryParameters) return previous.search !== current.search;
  return queryParameters.some(param => {
    return previous.searchParams.get(param) !== current.searchParams.get(param);
  });
}

function getSearchParamsCopy<T extends AnyObject>(
  url: URL,
  queryParameters?: Nullable<(keyof T)[]>
) {
  if (!queryParameters) {
    return Object.fromEntries(url.searchParams.entries());
  }

  return queryParameters.reduce((acc, param) => {
    Object.assign(acc, {[param]: url.searchParams.get(param.toString())});
    return acc;
  }, Object.create(null));
}

export function whenQueryParametersChange<T extends AnyObject>(
  item: unknown,
  queryParameterOrCallback: (keyof T)[] | QueryParameterListenerHandler<T>,
  callback?: QueryParameterListenerHandler<T>
) {
  const queryParameters =
    typeof queryParameterOrCallback === 'function'
      ? null
      : queryParameterOrCallback;

  const callbackFn =
    typeof queryParameterOrCallback === 'function'
      ? queryParameterOrCallback
      : callback;

  if (!callbackFn) {
    return () => {};
  }

  const unsubscribe = historyStateController.onAction(
    item,
    HistoryControllerActions.LOCATION_CHANGED,
    ({previous, current}) => {
      if (
        !hasQueryParametersChanged(
          {previous, current},
          queryParameters as string[]
        )
      ) {
        return;
      }
      const currentValue = getSearchParamsCopy(current, queryParameters);
      const previousValue = getSearchParamsCopy(previous, queryParameters);
      callbackFn(currentValue, previousValue);
    }
  );

  const currentValue = getSearchParamsCopy(
    new URL(location.href),
    queryParameters
  );
  callbackFn(currentValue, currentValue);

  return unsubscribe;
}

export function whenLocationChange(
  item: unknown,
  callback: LocationListenerHandler
) {
  historyStateController.onAction(
    item,
    HistoryControllerActions.LOCATION_CHANGED,
    ({previous, current}) => {
      if (previous.href === current.href) return;
      callback(current, previous);
    }
  );
}
