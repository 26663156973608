import {
  LitElement,
  PropertyDeclaration,
  PropertyDeclarations,
  html,
  CSSResult,
} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import React, {FunctionComponent} from 'react';
import {Root, createRoot} from 'react-dom/client';
import {KilnPlugin} from './utils';

interface _KilnProperty<Values> extends PropertyDeclaration {
  default?: Values;
}

type KilnProperties<Props extends object> = {
  [Property in keyof Props]: PropertyDeclaration & {
    default?: Props[Property];
  };
};

const kilnTagRegistry = new Set<string>();

export function isKilnElement(element: Element): boolean {
  return (
    '__IS_KILN_ELEMENT__' in element && element.__IS_KILN_ELEMENT__ === true
  );
}

/**
 * Generates a LitElement component given a react component.
 */
export function kiln<
  ReactProps extends object,
  KilnProps extends object = ReactProps,
>(
  tagName: string,
  {
    component,
    styles,
    props: declarations,
    plugins = [],
  }: {
    component: FunctionComponent<ReactProps>;
    props: KilnProperties<KilnProps>;
    styles?: CSSResult[] | CSSResult;
    plugins?: KilnPlugin[];
  }
): typeof LitElement {
  @customElement(tagName)
  class KilnElement extends LitElement {
    @property({type: Boolean, attribute: true, reflect: true})
    island = false;

    @property({type: Boolean, attribute: true, reflect: true})
    shadow = !!styles;

    slotElements: Element[] = [];

    reactRoot?: Root;
    rootElement?: React.ReactNode;

    private readonly __IS_KILN_ELEMENT__ = true;

    static get properties(): PropertyDeclarations {
      return declarations as PropertyDeclarations;
    }

    static get styles() {
      if (!styles) return;
      return styles;
    }

    protected setDefaults() {
      for (const key in declarations) {
        const prop = declarations[key];
        if (prop.default) {
          (this as typeof declarations)[key] = prop.default;
        }
      }
    }

    protected generateProps(this: KilnElement): ReactProps {
      const props: Partial<ReactProps> = {};

      for (const key in declarations) {
        const kilnPropKey = key as keyof KilnElement;
        const propKey = key as unknown as keyof ReactProps;
        const propValue = this[
          kilnPropKey
        ] as unknown as ReactProps[keyof ReactProps];
        Object.assign(props, {[propKey]: propValue});
      }

      return props as ReactProps;
    }

    constructor() {
      super();
      this.setDefaults();
    }

    connectedCallback(): void {
      super.connectedCallback();

      this.reactRender();

      if (this.island) {
        this.island = true;

        const observer = new MutationObserver(mutations => {
          mutations.forEach(mutation => {
            if (mutation.addedNodes || mutation.removedNodes) {
              // this.island = (mutation.target as Element).children.length !== 0;
            }
          });
        });

        observer.observe(this.renderRoot, {
          childList: true,
        });
      }
    }

    disconnectedCallback() {
      this.reactRoot?.unmount();
      super.disconnectedCallback();
    }

    updated(changedProperties: Map<string, unknown>) {
      super.updated(changedProperties);
      this.reactRender();
    }

    protected createRenderRoot(): Element | ShadowRoot {
      if (this.shadow) return super.createRenderRoot();
      return this;
    }

    protected gatherChildren(children: NodeListOf<ChildNode> | HTMLCollection) {
      const reactChildren: React.ReactNode[] = [];

      for (const node of children) {
        if (node instanceof HTMLSlotElement) {
          continue;
        }

        if (node instanceof Element && isKilnElement(node)) {
          const kilnElement = node as KilnElement;
          kilnElement.reactRender();
          if (kilnElement.rootElement) {
            reactChildren.push(kilnElement.rootElement);
          }
          continue;
        }

        /*
        if (node instanceof LitElement) {
          // FIXME(joshua): Get this working
          const _component = createComponent({
            tagName: node.tagName,
            elementClass: node.constructor as any,
            react: React as any,
          })
          continue;
        }*/

        if (node instanceof Text) {
          reactChildren.push(node.textContent);
          continue;
        }

        if (node instanceof Element && !isKilnElement(node)) {
          reactChildren.push(
            React.createElement(
              node.tagName.toLowerCase(),
              {key: Math.random()},
              node.childNodes.length > 0
                ? this.gatherChildren(node.childNodes)
                : node.textContent
            )
          );
          continue;
        }
      }

      return reactChildren;
    }

    reactRender() {
      if (!this.reactRoot) {
        this.reactRoot = createRoot(this.renderRoot);
      }

      plugins.forEach(p => p.beforeRender?.(this));

      const root = React.createElement(
        component,
        this.generateProps(),
        this.gatherChildren(this.childNodes)
      );

      this.rootElement = plugins
        .filter(plugin => !!plugin.render)
        .map(plugin => plugin.render!)
        .reduce((r: React.ReactNode, fn) => fn(r, this), root);

      this.reactRoot.render(this.rootElement);
      this.island = false;

      plugins.forEach(p => p.afterRender?.(this));
    }

    render() {
      return html``;
    }
  }

  return KilnElement;
}

export const ignite = (additionalTags?: string[] | Set<string>) => {
  if (document) {
    const ready = (() => {
      let fired = false;

      return async () => {
        if (fired) return;

        if (kilnTagRegistry.size === 0) {
          document.body.classList.add('kiln-ready');
          fired = true;
          return;
        }

        try {
          await Promise.allSettled(
            [...kilnTagRegistry, ...(additionalTags ?? [])].map(
              customElements.whenDefined
            )
          );
        } catch (err) {
          console.error(err);
        } finally {
          document.body.classList.add('kiln-ready');
          fired = true;
        }
      };
    })();

    document.onreadystatechange = async () => ready();
  }
};
