// architecture.ts

import {
  Variant,
  makeVariants,
  ImmutableResult,
  InitialProperties,
  Cmd,
} from '@v2/components/mixins/immutable_component';
import {
  FeatureFlagsManager,
  featureFlagsManager,
} from '@v2/lib/feature_flags/FeatureFlagsManager';
import {VariantsFor} from '@v2/types/architecture';
import {ApplicationFeatureFlags} from '@v2/types/feature_flags';
import {Nullable} from '@v2/types/general';
import {User} from '@v2/types/user';
import {SessionProvider} from '.';

export type Model = {
  user: Nullable<User>;
  embed: boolean;
  flags: FeatureFlagsManager;
};

export const Msg = makeVariants({
  setUser: Variant<Model['user']>()('set-user'),
});
export type Msg = VariantsFor<typeof Msg>;

function setProvidedUser(user: Nullable<User>): Nullable<User> {
  const userCopy = Object.assign(Object.create(null), user);
  return Object.freeze(userCopy);
}

function init(props: InitialProperties, cmd: Cmd): ImmutableResult<Model> {
  featureFlagsManager.setupFlags(
    <ApplicationFeatureFlags>props.get('flags') ?? {}
  );

  return [
    {
      user: setProvidedUser(<User>props.get('user') ?? null),
      embed: props.get('embed') === 'true',
      flags: featureFlagsManager,
    },
    cmd((host: SessionProvider) => {
      host.session.updateObservers();
    }),
  ];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function update(msg: Msg, model: Model, cmd: Cmd): ImmutableResult<Model> {
  switch (msg.tag) {
    case 'set-user':
      return [
        {...model, user: msg.value} as const,
        cmd((host: SessionProvider) => {
          host.session.updateObservers();
        }),
      ];
  }
}

export const main = {init, update};
