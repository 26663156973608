import {RouteMap} from '../types/routes';
import {getRule} from '../utils/routes';
import {FlywheelComponent} from './component';
import {FlywheelRouter} from './router';

export class App {
  name: string;
  routes: RouteMap;
  pathname: string;

  constructor({name, routes}: {name: string; routes: RouteMap}) {
    this.name = name;
    this.routes = routes;
    this.pathname = window.location.pathname;
  }

  init() {
    Object.values(this.routes)
      .filter(route => {
        return (
          route.paths.includes('*') ||
          route.paths.includes(this.pathname) ||
          getRule(this.pathname, route.paths)
        );
      })
      .forEach(({components, paths}) => {
        for (const key in components) {
          const loader = components[key];

          // Add static paths
          FlywheelComponent.paths = paths;

          // Adds router
          FlywheelComponent.router = new FlywheelRouter(this.routes);

          loader().catch(err => {
            throw err;
          });
        }
      });
  }
}
