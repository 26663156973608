import {AnyFunction, NonUndefined} from '@v2/types/general';

/**
 * Determines if a value is a function
 *
 * NOTE: This function lies around it generic use.
 * We are only checking it is a function, but the interface
 * is a type assertion instead of inferred or tested
 */
export const isFunction = <T extends AnyFunction>(
  value: unknown
): value is T => {
  return typeof value === 'function';
};

/** Determines if a value is undefined */
export const isUndefined = (value: unknown): value is undefined =>
  typeof value === 'undefined';

/**
 * Determines if a value is null
 *
 * This function should use triple sign, but is using only double
 * and now it is hard to revert it.
 * Be warned that it will return true for undefined as well.
 */
// eslint-disable-next-line eqeqeq
export const isNull = (value: unknown): value is null => value == null;

/** Determines if a value is null, undefined or NaN */
export const isNil = (value: unknown): value is null | undefined | void =>
  isUndefined(value) ||
  isNull(value) ||
  (typeof value === 'number' && isNaN(value));

/** Determines if a value is a string or not */
export const isString = (value: unknown): value is string =>
  typeof value === 'string';

/** Determines whether or not string is numeric. */
export const isNumericString = (value: unknown): boolean => {
  if (!isString(value)) return false;

  if (/[a-zA-Z]/.test(value)) return false;

  if (!isNaN(parseFloat(value))) {
    if (BigInt(Math.floor(Number(value))) > Number.MAX_SAFE_INTEGER) {
      return false;
    }

    return true;
  }

  return false;
};

/** Determines whether or not string is a JSON-style boolean. */
export const isBooleanString = (value: unknown): boolean => {
  if (!isString(value)) return false;
  return value === 'true' || value === 'false';
};

/** Determines if a value is a number or not */
export const isNumber = (value: unknown): value is number =>
  typeof value === 'number';

/** Determines if a value is a boolean or not */
export const isBool = (value: unknown): value is boolean =>
  typeof value === 'boolean';

/** Determines if a value is an empty string or not */
export const isEmptyString = (value: unknown): value is '' =>
  typeof value === 'string' && value.trim().length === 0;

/** Determines if a value is a nonempty string or not */
export const isNonEmptyString = (value: unknown): value is string =>
  typeof value === 'string' && value.trim().length > 0;

/** Determines if a value is a record object */
export function isPlainObject(x: unknown): x is Record<string, unknown> {
  return (
    typeof x === 'object' &&
    x !== null &&
    !Array.isArray(x) &&
    /**
     * Object.create(null) is an object without a prototype,
     * so the constructor test will fail for it.
     */
    (('constructor' in x && x.constructor.name === 'Object') ||
      Object.getPrototypeOf(x) === null)
  );
}

export function isPrimitive(
  x: unknown
): x is string | number | boolean | undefined | null {
  return x === null || !(typeof x === 'object' || typeof x === 'function');
}

/** Intersection  of two sets */
export function checkSetIntersection(a: Set<unknown>, b: Set<unknown>) {
  const intersection = new Set();
  const smaller = a.size <= b.size ? a : b;
  const bigger = smaller === a ? b : a;
  for (const elem of smaller) {
    if (bigger.has(elem)) {
      intersection.add(elem);
    }
  }
  return intersection;
}

/** Equality of two sets */
export function checkSetEquality(a: Set<unknown>, b: Set<unknown>) {
  if (a.size !== b.size) return false;
  const intersection = checkSetIntersection(a, b);
  return intersection.size === a.size;
}

/** Properly formats form input. */
export function formatFormInput(value: string): string | boolean | number {
  if (Array.isArray(value)) return value;

  if (isBooleanString(value)) {
    return value === 'true';
  }

  if (isNumericString(value)) {
    return parseFloat(value);
  }

  return value;
}

/** Determines whether form input is valid **/
export function isValidFormInput(value: unknown): boolean {
  // If form input is a string, return true only if empty;
  if (isString(value)) {
    return isNonEmptyString(value);
  }

  // If form value is a boolean or a number, return true since it's
  // valid input.
  if (isBool(value) || isNumber(value)) {
    return true;
  }

  // All other types are invalid.
  return false;
}

/**
 * Returns whether or not a value is undefined.
 */
export function isDefined<T>(x: T): x is NonUndefined<T> {
  return typeof x !== 'undefined';
}

/**
 * Returns whether or not a value is undefined.
 */
export function isNotNill<T>(x: T): x is NonNullable<T> {
  return typeof x !== 'undefined' && x !== null;
}

export function nextTick(fn: () => void) {
  setTimeout(() => {
    fn();
  }, 0);
}

/**
 * Ensures a non-array value is return from an array or non-array value
 */
export function ensureSingularValue<T>(value: T | T[]): T {
  if (Array.isArray(value)) {
    return value[0];
  }
  return value;
}

/**
 * Awaits for x milliseconds before running some execution
 */
export function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}
