import {ChannelController} from '@v2/controllers/state_controller';

export enum HistoryControllerActions {
  LOCATION_CHANGED = 'history:location-changed',
}

export type HistoryControllerActionMap = {
  [HistoryControllerActions.LOCATION_CHANGED]: {
    previous: URL;
    current: URL;
  };
};

export type HistoryStateController = ChannelController<
  HistoryControllerActionMap,
  {}
>;
