import {html} from 'lit';
import {Constructor, customElement, property} from 'lit-element';
import {state} from 'lit/decorators.js';
import {ContextProvider} from '@lit-labs/context';

import {Model, Msg, main} from './architecture';
import {ImmutableComponent} from '@v2/components/mixins/immutable_component';
import {FlywheelComponent} from '@v2/lib/component';
import {SessionContext} from '@v2/contexts/session';
import {Nullable} from '@v2/types/general';
import {User} from '@v2/types/user';
import {FeatureFlagKey} from '@v2/lib/feature_flags/FeatureFlag';
import {ApplicationFeatureFlags} from '@v2/types/feature_flags';
import styles from './styles.scss';

export const SESSION_PROVIDER_TAG = 'session-provider';

@customElement(SESSION_PROVIDER_TAG)
export class SessionProvider extends ImmutableComponent<
  Constructor<FlywheelComponent>,
  Model,
  Msg
>({component: FlywheelComponent, main}) {
  static get styles() {
    return [styles];
  }

  @property({type: Object})
  get user(): Nullable<User> {
    return this.model.user;
  }
  set user(value) {
    this.handleUpdate(Msg.setUser(value));
  }

  @property({type: String})
  embed = 'false';

  @property({type: Object})
  flags: ApplicationFeatureFlags = {};

  @state()
  session = new ContextProvider(
    this,
    SessionContext,
    Object.freeze({
      user: () => this.model.user,
      embed: () => this.model.embed,
      enabled: (...keys: FeatureFlagKey[]) => {
        return keys.every(key => this.model.flags.enabled(key));
      },
    })
  );

  render() {
    return html`<slot></slot>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [SESSION_PROVIDER_TAG]: SessionProvider;
  }
}
