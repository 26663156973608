import {
  ApplicationFeatureFlags,
  IFeatureFlagsManager,
} from '@v2/types/feature_flags';
import {FeatureFlagKey, featureFlags} from './FeatureFlag';

const featureFlagOverrides = Object.create(null);

if (__DEV__) {
  for (const {key} of featureFlags) {
    if (key in __LOCAL_ENV__) {
      featureFlagOverrides[key] = __LOCAL_ENV__[key]?.trim() === 'true';
    }
  }
}
Object.freeze(featureFlagOverrides);

export class FeatureFlagsManager implements IFeatureFlagsManager {
  private flags: ApplicationFeatureFlags = {};
  private handlers = new Map<
    FeatureFlagKey,
    Set<Readonly<[() => void]> | Readonly<[() => void, () => void]>>
  >();
  private isSetup = false;

  setupFlags(flags: ApplicationFeatureFlags = {}) {
    // FIXME(joshua): This is hack to fix duplicate calls of feature flags.
    if (Object.keys(flags).length === 0) return;

    const result = Object.create(null);

    for (const [key, value] of Object.entries(flags)) {
      result[key] = value;
    }

    for (const key of this.handlers.keys()) {
      const handlers = this.handlers.get(key)!;
      handlers.forEach(([handler, otherwise]) => {
        key in result && result[key] === true ? handler?.() : otherwise?.();
      });
    }

    if (__DEV__) {
      Object.assign(result, featureFlagOverrides);
    }

    this.isSetup = true;
    return (this.flags = Object.freeze(result));
  }

  enabled(featureFlag: FeatureFlagKey): boolean {
    return this.flags[featureFlag] ?? false;
  }

  whenEnabled(
    key: FeatureFlagKey,
    handler: () => void,
    otherwise?: () => void
  ) {
    if (this.enabled(key)) return handler();
    if (this.isSetup && !this.enabled(key) && otherwise) {
      return otherwise();
    }

    const value = otherwise
      ? ([handler, otherwise] as const)
      : ([handler] as const);

    if (!this.handlers.has(key)) {
      this.handlers.set(key, new Set());
    }

    this.handlers.get(key)!.add(value);
  }
}

export const featureFlagsManager = new FeatureFlagsManager();
