/**
 * Takes a pixel string (i.e., '24px') and a multiplier and returns
 * a pixel string
 */
export function multiplyPixelString(
  pixelStr: string,
  multiplier: number
): string {
  const pixelNum = Number(pixelStr.replace(/[^0-9]/g, ''));
  return `${pixelNum * multiplier}px`;
}

/**
 * Takes a string, returns an unsigned 32-bit number
 */
export function hashString(str: string) {
  let h = 0;

  for (let i = 0; i < str.length; i++) {
    h = (Math.imul(31, h) + str.charCodeAt(i)) | 0;
  }

  return Math.abs(h);
}

/**
 * Fuzzy matches a string with a string
 * (modified version of bevacqua/fuzzysearch)
 */
export function fuzzyMatch(needle: string, haystack: string): boolean {
  const hlen = haystack.length;
  const nlen = needle.length;

  if (nlen > hlen) return false;
  if (nlen === hlen) return needle === haystack;

  outer: for (let i = 0, j = 0; i < nlen; i++) {
    const nch = needle.charCodeAt(i);
    while (j < hlen) {
      if (haystack.charCodeAt(j++) === nch) continue outer;
    }

    return false;
  }

  return true;
}

/**
 * Makes text safe for grep
 */
export function safeToGrep(str: string) {
  return str.trim().toLocaleLowerCase();
}

/** Orders two strings; is case insensitive */
export function sortStrings(a: string, b: string) {
  const _a = a.toLowerCase();
  const _b = b.toLowerCase();

  if (_a < _b) return -1;
  if (_a > _b) return 1;
  return 0;
}

export function truncateAndKeepWord(text: string, maxLength: number): string {
  return text.replace(new RegExp(`^(.{${maxLength}}[^\\s]*).*`), '$1');
}

// You can use webkit line clamping to do this with better UI consistency in CSS
// with `display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;`
// can checkout the frontend/v2/src/components/marve/home_card/style.scss for an example
export function truncateWithEllipses(text: string, maxLength: number): string {
  if (text.length <= maxLength) return text;

  return text.substring(0, maxLength - 1) + '...';
}

export function isJSON(text: string): boolean {
  try {
    JSON.parse(text);
    return true;
  } catch {
    return false;
  }
}

export function formatJSON(data: unknown): string {
  return JSON.stringify(data, null, 4);
}

export function formatFieldSearch(phrase: string): string {
  return phrase
    .replace(/[^\w\s]|_/gi, ' ')
    .split(' ')
    .map((word, index) =>
      index === 0
        ? word.charAt(0).toUpperCase() + word.slice(1)
        : word.toLowerCase()
    )
    .join(' ');
}

export function titleize(str: string) {
  if (str.length === 0) return '';
  return str[0].toUpperCase() + str.toLowerCase().slice(1);
}

export function titleizeAllWords(
  str: string,
  options?: {
    splitter?: string | RegExp;
    joiner?: string;
  }
) {
  const config = Object.assign({splitter: ' ', joiner: ' '}, options);
  return str.split(config.splitter).map(titleize).join(config.joiner);
}

export function pluralize(
  quantity: number,
  singularForm: string,
  extra?: {
    pluralForm?: string;
    zeroForm?: string;
  }
): string {
  const plural = extra?.pluralForm ?? `${singularForm}s`;
  if (quantity === 0) {
    return `0 ${extra?.zeroForm ?? plural}`;
  }

  if (-2 < quantity && quantity < 2) {
    return `${quantity.toLocaleString()} ${singularForm}`;
  }

  return `${quantity.toLocaleString()} ${plural}`;
}

export function hasSpecialCharacters(str: string) {
  // This regex matches any character that is not a letter, digit or underscore
  const regex = /[^a-zA-Z0-9_: -]/;
  return regex.test(str);
}
