import {Route, RouteMap} from '@v2/types/routes';
import {whenReady} from '@v2/utils/dom';
import {getRule} from '@v2/utils/routes';
import {FlywheelComponent} from './component';
import {browserHistory} from './history';

export const APP_ROOT_ID = 'app';

export class FlywheelRouter {
  pathname = location.pathname;
  origin = location.origin;
  route?: Route | null;
  component?: (new () => FlywheelComponent) | null;
  $appRoot!: HTMLElement | null;
  $rootElement!: HTMLElement | null;

  constructor(private routes: RouteMap = {}) {
    this.routes = routes;
    this.initializeRoute();

    window.addEventListener('popstate', () => {
      this.initializeRoute();
    });
  }

  private setRoute() {
    this.route = Object.values(this.routes).find(route => {
      return (
        route.page &&
        (route.paths.includes('*') ||
          route.paths.includes(this.pathname) ||
          getRule(this.pathname, route.paths))
      );
    });
  }

  private async setComponent() {
    if (this.route?.rootComponent) {
      const loader = this.route.rootComponent;

      try {
        const {default: component} = await loader();
        this.component = component as new () => FlywheelComponent;
      } catch (err) {
        console.error(err);
      }
    }
  }

  private setAppRoot() {
    whenReady(() => {
      const rootId = this.route?.appRoot || APP_ROOT_ID;
      this.$appRoot = document.getElementById(rootId);
    });
  }

  private generateURL(path: string) {
    return this.origin + path;
  }

  private reset() {
    this.pathname = location.pathname;
    this.component = this.route = null;

    if (this.$rootElement) this.$rootElement.remove();
  }

  async initializeRoute() {
    this.reset();

    try {
      this.setRoute();

      if (this.route) {
        this.setAppRoot();
        await this.setComponent();

        this.renderComponent();
      }
    } catch (err) {
      console.error(err);
    }
  }

  renderComponent() {
    if (!this.$appRoot || !this.route || !this.component) return;
    const tagName = new this.component().tagName;
    this.$rootElement = document.createElement(tagName);

    this.$appRoot.appendChild(this.$rootElement);
  }

  navigate(path: string, state: {[k: string]: unknown} = {}) {
    browserHistory.pushState(state, path, this.generateURL(path));
    this.initializeRoute();
  }
}
