// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.91.0/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../node_modules/.pnpm/sass-loader@14.2.1_sass-embedded@1.77.8_sass@1.77.2_webpack@5.91.0/node_modules/sass-loader/dist/cjs.js!../../node_modules/.pnpm/@radix-ui+themes@3.0.5_@types+react-dom@18.3.0_@types+react@18.3.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@radix-ui/themes/styles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{--font-weight-bold: bold;--font-weight-light: 300;--font-weight-regular: normal;--space-1: 6px;--space-2: 12px;--space-3: 18px;--space-4: 24px;--space-5: 30px;--space-6: 36px;--space-7: 42px;--space-8: 48px;--space-9: 54px;--space-10: 60px;--space-11: 66px;--space-12: 72px;--space-13: 78px;--space-14: 84px;--space-15: 90px;--space-16: 96px}.radix-themes:where([data-is-root-theme=true]){min-height:unset !important}`, "",{"version":3,"sources":["webpack://./../../packages/ui/global.scss"],"names":[],"mappings":"AAKA,MACE,wBAAA,CACA,wBAAA,CACA,6BAAA,CAEA,cAAA,CACA,eAAA,CACA,eAAA,CACA,eAAA,CACA,eAAA,CACA,eAAA,CACA,eAAA,CACA,eAAA,CACA,eAAA,CACA,gBAAA,CACA,gBAAA,CACA,gBAAA,CACA,gBAAA,CACA,gBAAA,CACA,gBAAA,CACA,gBAAA,CAIF,+CACE,2BAAA","sourcesContent":["@use '@gl/styles/modules/layout.scss' as layout;\n@import '@radix-ui/themes/styles.css';\n\n$spacing: 6px;\n\n:root {\n  --font-weight-bold: bold;\n  --font-weight-light: 300;\n  --font-weight-regular: normal;\n\n  --space-1: #{$spacing};\n  --space-2: #{$spacing * 2};\n  --space-3: #{$spacing * 3};\n  --space-4: #{$spacing * 4};\n  --space-5: #{$spacing * 5};\n  --space-6: #{$spacing * 6};\n  --space-7: #{$spacing * 7};\n  --space-8: #{$spacing * 8};\n  --space-9: #{$spacing * 9};\n  --space-10: #{$spacing * 10};\n  --space-11: #{$spacing * 11};\n  --space-12: #{$spacing * 12};\n  --space-13: #{$spacing * 13};\n  --space-14: #{$spacing * 14};\n  --space-15: #{$spacing * 15};\n  --space-16: #{$spacing * 16};\n}\n\n/* Removes strange height when using Radix themes. */\n.radix-themes:where([data-is-root-theme=\"true\"]) {\n  min-height: unset !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
