class AssertionError extends Error {
  name = 'AssertionError';
  data?: unknown;

  constructor(message: string, options?: {data?: unknown}) {
    super(message);
    this.data = options?.data;
  }
}

/**
 * Asserts that a given condition is true during development.
 * If the condition is false, an error message is logged to the console.
 * This function is typically used for debugging
 * and catching unexpected conditions during development.
 *
 * @param {() => boolean} assertion -
 *  The condition to assert, expressed as a function returning a boolean.
 * @param {string} errorMessage -
 *  The error message to log if the assertion fails.
 * @param {object} [options] -
 *  Additional options, such as providing extra data for debugging.
 * @param {unknown} [options.data] -
 *  Additional data to be logged along with the error message.
 *
 * @returns {boolean} -
 *  Returns true if the assertion passes or
 *    if the code is not in a development environment.
 *  Returns false if the assertion fails,
 *    and an error message is logged to the console.
 */
export function assert(
  assertion: () => boolean,
  errorMessage: string,
  options?: {data?: unknown}
): boolean {
  // Check if the code is in a development environment.
  if (!__DEV__) return true;

  // Check the given assertion.
  if (!assertion()) {
    // Log an error message along with optional data to the console.
    console.error(errorMessage, options?.data);

    // Assertion failed; return false.
    return false;
  }

  // Assertion passed; return true.
  return true;
}

/**
 * Asserts that a given condition is true during development.
 * If the condition is false, an error message is logged to the console,
 * and an exception is thrown to interrupt program execution.
 * This function is useful for catching critical errors that should halt the program.
 *
 * If you need a non halting version, use {@link assert}
 *
 * @param {() => boolean} assertion -
 *  The condition to assert, expressed as a function returning a boolean.
 * @param {string} errorMessage -
 *  The error message to log and throw if the assertion fails.
 * @param {object} [options] -
 *  Additional options, such as providing extra data for debugging.
 * @param {unknown} [options.data] -
 *  Additional data to be logged along with the error message.
 *
 * @throws {AssertionError} -
 *  Throws an AssertionError if the assertion fails.
 *  This error includes the specified error message
 *  and additional data for debugging.
 *
 * @returns {boolean} -
 *  Returns true if the assertion passes or
 *    if the code is not in a development environment.
 *  Returns false if the assertion fails,
 *    and an error message is logged to the console.
 */
export function throwableAssert(
  assertion: () => boolean,
  errorMessage: string,
  options?: {data?: unknown}
): boolean {
  const result = assert(assertion, errorMessage, options);
  if (!result) {
    throw new AssertionError(errorMessage, options);
  }
  return result;
}
